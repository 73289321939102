/*================================================================================
	Item Name: Kode
	Description: Premium Bootstrap Admin Template
	Author: Egemen Kapusuz
	Author URL: http://www.themeforest.net/user/egemem
================================================================================*/

/* =================================================================================
smaller than 1280
================================================================================= */
@media screen and (max-width: 1280px) {
	.topmenu{display: none;}
}

/* =================================================================================
smaller than 1024
================================================================================= */
@media screen and (max-width: 1024px) {
	.profilebox b{display: none;}
	.searchform{display: none;}
	.sidebar{display: none;}
	.content{margin-left: 0 !important;}
	.sidebar-open-button{display: none;}
	.sidebar-open-button-mobile{display: block;}
	.social-stats{bottom: 100px;}
	.topstats li{width: 33%;}
	.demo-sidebarmenu .sidebar{display: block;}
}

/* =================================================================================
bigger than 1024
================================================================================= */
@media screen and (min-width: 1024px) {
	.sidebar{display: block !important;}
}

/* =================================================================================
smaller than 760
================================================================================= */
@media screen and (max-width: 760px) {
	.page-header .right{display: none;}
	.sidebar{display: none; width: 100%;}
	.presentation .list{float: left; margin-top: 20px;}
	.applogo{display: none;}
	.container-default, .container-widget{margin-left:-20px; margin-right: -20px;}
	.container-padding, .container-no-padding{margin-left:-40px; margin-right: -40px;}
	.social-content{padding-left: 10px; padding-right: 10px;}
	.chart-with-stats .top-label{right: 20px;}
	.mailbox-menu .menu li{display: block; width: 100%; border-right:none; border-bottom: 1px solid rgba(0, 0, 0, 0.08);}
	.chat .title{height: auto;}
	.profile-left .btn{display: none;}
	.topstats li{width: 50%;}
}

/* =================================================================================
smaller than 428
================================================================================= */
@media screen and (max-width: 428px) {
	.top-right{display: none;}
	.applogo{
		padding-left: 0;
		background: none;
		display: block;
		position: absolute;
		width: 100%;
		z-index: -4;
	}
	.topstats li{width: 100%;}
}
