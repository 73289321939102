@import '~antd/dist/antd.css';


#fm-toolbar-wrapper {
    height: 50px !important;
}
#fm-toolbar-wrapper #fm-toolbar li a svg {
    height: 30px;
}
#fm-toolbar-wrapper #fm-toolbar a {
    height: 60px !important;
}
#fm-toolbar-wrapper #fm-toolbar > li > a span, #fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-right > li > a span {
    top: 30px !important;
}
#fm-toolbar-wrapper #fm-toolbar > li > a span, #fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-left > li > a span {
    top: 30px !important;
}

#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content a {
    height: 27px !important;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown {
    top: 30px !important;
}

#fm-pivot-view .fm-grid-layout .fm-empty {
  border-color: transparent !important;
}

#fm-pivot-view .fm-grid-layout #fm-data-sheet {
  border-top-color: transparent !important;
}

/* Dashbaord SE CSS 
.compliance_table{
  padding: 0px 10px;
  min-width: 170px;
}

/* Main Landing Dashboard CSS*/
.module_cnt{
  background: #ffffff8c;
  text-align: center;
  padding-top:20px;
  /* height: 300px; */
  height: 230px;
  cursor:pointer;
  margin-bottom: 20px;
  box-shadow: 9px 10px 10px #9E9E9E;
}

.module_cnt h2{
  font-family: 'Montserrat',sans-serif;
  text-align: center;
  color: #E2010F;
  font-weight: 800;
}


.menu_Bar_Link:active{
  color:red
}

.sidebar-panel li ul li a:active{
  color:red
}

/* Customize css */
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc{
  box-shadow: inset 0 3px 0 0 rgb(226, 1, 15);
}
.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgb(226, 1, 15);
}


.login-form{
  float:left;
  padding-top: 0!important;
}
.login-form form{
  height: 755px!important;
  border-radius: 0!important;

}

.login-form form .form-area .checkbox {
    margin-bottom: 20px;
    text-align: left;
    margin-left: 20px;
  }


.login-form form .top{
  border: none!important;
}


.login-form form .form-area .group{
  text-align: left;
}




.form_label{
  text-align: left;
  margin-right: 20px;
}



.form_input{
  margin-bottom: 20px;
  width: 100%;
}

.shadow {
  height: 100%;
  width: 100%;
  box-shadow: 7px 7px 7px #9E9E9E;
}