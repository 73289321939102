inputf.color1 {
    color: #37363e
}

.color2 {
    color: #58666e
}

.color3 {
    color: #e4e4e4
}

.color4 {
    color: #f5f5f5
}

.color5 {
    color: #399bff
}

.color6 {
    color: #33577b
}

.color7 {
    color: #26a65b
}

.color8 {
    color: #51b7a3
}

.color9 {
    color: #f39c12
}

.color10 {
    color: #ef4836
}

.color11 {
    color: #9a80b9
}

.color12 {
    color: #a2ded0
}

.color13 {
    color: #d2527f
}

.color14 {
    color: #c78568
}

.color15 {
    color: #e99844
}

.color0-bg {
    background: #3d464d
}

.color1-bg {
    background: #37363e
}

.color2-bg {
    background: #58666e
}

.color3-bg {
    background: #e4e4e4
}

.color4-bg {
    background: #f5f5f5
}

.color5-bg {
    background: #399bff
}

.color6-bg {
    background: #33577b
}

.color7-bg {
    background: #26a65b
}

.color8-bg {
    background: #51b7a3
}

.color9-bg {
    background: #f39c12
}

.color10-bg {
    background: #ef4836
}

.color11-bg {
    background: #9a80b9
}

.color12-bg {
    background: #a2ded0
}

.color13-bg {
    background: #d2527f
}

.color14-bg {
    background: #c78568
}

.color15-bg {
    background: #e99844
}

.color-up {
    color: #26a65b
}

.color-down {
    color: #ef4836
}

.color-fix {
    color: #399bff
}

body {
    background: #3d464d;
    color: #58666e;
    margin: 0;
    line-height: 1.7em;
    font-size: 13px;
    font-family: 'Roboto',sans-serif;
    outline: 0;
    text-Shadow: 0 0 1px rgba(0,0,0,0.2)
}

::-moz-selection {
    background: #D5EAFF
}

::selection {
    background: #D5EAFF
}

a:hover, a:focus {
    text-decoration: none
}

b, strong {
    font-weight: 600
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-right: 15px;
    padding-left: 15px
}

.row {
    margin-right: -15px;
    margin-left: -15px
}

.container-widget .col-xs-1, .container-widget .col-sm-1, .container-widget .col-md-1, .container-widget .col-lg-1, .container-widget .col-xs-2, .container-widget .col-sm-2, .container-widget .col-md-2, .container-widget .col-lg-2, .container-widget .col-xs-3, .container-widget .col-sm-3, .container-widget .col-md-3, .container-widget .col-lg-3, .container-widget .col-xs-4, .container-widget .col-sm-4, .container-widget .col-md-4, .container-widget .col-lg-4, .container-widget .col-xs-5, .container-widget .col-sm-5, .container-widget .col-md-5, .container-widget .col-lg-5, .container-widget .col-xs-6, .container-widget .col-sm-6, .container-widget .col-md-6, .container-widget .col-lg-6, .container-widget .col-xs-7, .container-widget .col-sm-7, .container-widget .col-md-7, .container-widget .col-lg-7, .container-widget .col-xs-8, .container-widget .col-sm-8, .container-widget .col-md-8, .container-widget .col-lg-8, .container-widget .col-xs-9, .container-widget .col-sm-9, .container-widget .col-md-9, .container-widget .col-lg-9, .container-widget .col-xs-10, .container-widget .col-sm-10, .container-widget .col-md-10, .container-widget .col-lg-10, .container-widget .col-xs-11, .container-widget .col-sm-11, .container-widget .col-md-11, .container-widget .col-lg-11, .container-widget .col-xs-12, .container-widget .col-sm-12, .container-widget .col-md-12, .container-widget .col-lg-12 {
    padding-right: 5px;
    padding-left: 5px
}

.container-widget .row {
    margin-right: -5px;
    margin-left: -5px
}

.container-widget .panel {
    margin-bottom: 10px
}

.container-widget .widget {
    margin-bottom: 10px
}

.container-default {
    padding: 0
}

.container-widget {
    padding: 0;
    margin-left: -5px;
    margin-right: -5px
}

.container-padding {
    padding-left: 20px;
    padding-right: 20px
}

.container-no-padding {
    padding: 0px;
    margin: -20px -30px 0 -30px
}

.container-mail {
    padding: 0px;
    margin: -20px -30px 0 -30px
}

#top {
    height: 100%;
    background: #98030d;
    color: #fff;
    width: 100%;
    z-index: 900;
    top: 0
}

.applogo {
    width: 15%;
    height: 60px;
    background: #e1000f;
    color: #fff;
    position: relative;
    padding: 5px;
    float: left;
    text-align: center
}

    .applogo .logo {
        color: #fff;
        font-size: 30px;
        font-weight: bold;
        font-family: 'Montserrat',sans-serif
    }

.sidebar-open-button {
    height: 60px;
    font-size: 22px;
    display: block;
    width: 62px;
    text-align: center;
    float: left;
    color: #fff;
    padding-top: 19px;
    border-right: 1px solid rgba(0,0,0,0.14);
    z-index: 1
}

    .sidebar-open-button:hover, .sidebar-open-button:active {
        background: rgba(255,255,255,0.07)
    }

.sidebar-open-button-mobile {
    height: 60px;
    display: none;
    font-size: 22px;
    width: 62px;
    text-align: center;
    float: left;
    color: rgba(0,0,0,0.6);
    padding-top: 19px;
    border-right: 1px solid rgba(0,0,0,0.14)
}

    .sidebar-open-button-mobile:hover, .sidebar-open-button-mobile:active {
        background: rgba(255,255,255,0.07)
    }

.searchform {
    width: 220px;
    margin: 0 20px;
    float: left;
    padding-top: 14px;
    position: relative
}

.searchbox {
    border-radius: 999px;
    border: none;
    height: 32px;
    width: 220px;
    padding-left: 20px;
    padding-right: 36px;
    background: rgba(255,255,255,0.95);
    color: #37363e;
    box-shadow: inset none
}

    .searchbox:focus {
        background: #fff;
        box-shadow: none;
        border-top: none
    }

.searchbutton {
    border: none;
    color: rgba(0,0,0,0.5);
    background: none;
    position: absolute;
    top: 18px;
    font-size: 16px;
    right: 15px
}

.topmenu {
    float: left;
    padding-top: 20px;
    padding-left: 0;
    font-weight: 600;
    margin-left: -7px
}

    .topmenu a {
        color: rgba(255,255,255,0.9);
        padding: 7px
    }

        .topmenu a:hover {
            color: #fff
        }

    .topmenu li {
        display: inline
    }

.sidepanel-open-button {
    height: 60px;
    font-size: 22px;
    display: block;
    width: 62px;
    text-align: center;
    float: right;
    color: rgba(0,0,0,0.6);
    padding-top: 19px;
    border-left: 1px solid rgba(0,0,0,0.14);
    z-index: 1
}

    .sidepanel-open-button:hover, .sidepanel-open-button:active {
        background: rgba(255,255,255,0.05)
    }

.top-right {
    line-height: 1.8em;
    float: right;
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 14px;
    font-weight: 600
}

    .top-right .link {
        display: inline-block;
        height: 30px;
        margin-top: 2px;
    }

        .top-right .link a {
            display: block
        }

    .top-right a {
        color: #fff
    }

    .top-right .profilebox {
        color: rgba(255,255,255,0.95)
    }

        .top-right .profilebox img {
            width: 32px;
            height: 31px;
            float: left;
            border-radius: 100%;
            margin-right: 7px;
            margin-top: -4px
        }

        .top-right .profilebox .caret {
            margin-left: 5px;
            color: rgba(255,255,255,0.5)
        }

        .top-right .profilebox:hover {
            color: #fff
        }

    .top-right .notifications {
        color: #fff;
        padding: 4px 12px;
        font-weight: bold;
        background: rgba(0,0,0,0.4);
        border-radius: 999px
    }

        .top-right .notifications:hover {
            background: rgba(0,0,0,0.5)
        }

    .top-right .hdbutton {
        background: #fff;
        color: #404B5F;
        padding: 4px 16px;
        border-radius: 999px
    }

        .top-right .hdbutton:hover {
            background: rgba(255,255,255,0.95)
        }

.sidebar {
    min-height: 855px;
    width: 100%;
    top: 60px;
    left: 0;
    font-family: 'Montserrat',sans-serif;
    background: #3d464d;
    font-size: 13px;
    font-weight: normal;
    z-index: 500;
    color: rgba(255,255,255,0.8)
}

    .sidebar .label {
        font-size: 10px;
        position: relative;
        line-height: 12px;
        padding: 2px 5px;
        position: absolute;
        right: 35px;
        top: 12px;
        font-weight: 600
    }

    .sidebar .label-default {
        background: rgba(255,255,255,0.2)
    }

.sidebar-panel {
    background: none;
    margin: 0px 20px 0px 20px;
    border: none;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding: 15px 0px
}

    .sidebar-panel li a {
        display: block;
        color: rgba(255,255,255,0.6);
        padding: 9px 20px 9px 20px;
        margin-left: -20px;
        margin-right: -20px;
        position: relative
    }

        .sidebar-panel li a .caret {
            position: absolute;
            right: 20px;
            top: 18px;
            color: rgba(255,255,255,0.4)
        }

    .sidebar-panel li .icon {
        position: absolute;
        left: 20px;
        top: 8px;
        display: block;
        color: rgba(255,255,255,0.6);
        font-size: 19px;
        border-radius: 3px;
        text-align: center;
        width: 24px;
        height: 24px;
        padding-top: 1px
    }

    .sidebar-panel li a:hover, .sidebar-panel li a.active {
        color: #f2f2f2;
        background: rgba(0,0,0,0.2)
    }

        .sidebar-panel li a:hover .icon, .sidebar-panel li a.active .icon {
            color: #fff
        }

    .sidebar-panel li ul {
        display: none;
        padding: 0;
        background: rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
        font-size: 13px;
        margin: 0 -20px 0 -20px;
        font-weight: 400
    }

        .sidebar-panel li ul li {
            position: relative;
            margin: 0;
			background: #1a252b;
        }

            .sidebar-panel li ul li a {
                margin: 0px;
                color: rgba(255,255,255,0.6);
                padding: 9px 20px 9px 20px
            }

                .sidebar-panel li ul li a:hover {
                    background: rgba(0,0,0,0.08)
                }

.sidetitle {
    color: rgba(255,255,255,0.5);
    font-size: 10px;
    padding: 0;
    margin: 0 0 5px 3px;
    letter-spacing: 0.4px
}

.sidebar-plan {
    padding: 20px;
    font-size: 12px;
    bottom: 0
}

    .sidebar-plan .progress {
        height: 6px;
        background: rgba(0,0,0,0.3);
        margin: 7px 0
    }

    .sidebar-plan .progress-bar {
        background: #399bff
    }

    .sidebar-plan .link {
        position: absolute;
        right: 20px;
        color: rgba(255,255,255,0.6)
    }

        .sidebar-plan .link:hover {
            color: #fff
        }

    .sidebar-plan .space {
        display: block;
        font-size: 11px;
        text-align: center;
        color: rgba(255,255,255,0.5)
    }

.flex {
    display: flex;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.mh-800 {
    min-height: 800px
}

.appBackground {
    background: #e4e4e4;
}

.w-100 {
    width: 100%;
}

.activeSub {
    color: red !important;
}

.alignCenter {
    align-items: center;
}

.mr-3 {
    margin-right: 30px;
}

.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.w-80 {
    width: 80%;
}

.w-20 {
    width: 20%;
}

.w-85 {
    width: 85%;
}

.w-15 {
    width: 15%;
}

.error {
    color: red;
}

.errorBox {
    border: 1px solid red;
}

.sidebar-colorful .sidebar-panel li a .icon {
    font-size: 15px;
    color: #fff
}

.sidebar-colorful .color1 {
    background: #399bff
}

.sidebar-colorful .color2 {
    background: #33577b
}

.sidebar-colorful .color3 {
    background: #26a65b
}

.sidebar-colorful .color4 {
    background: #51b7a3
}

.sidebar-colorful .color5 {
    background: #f39c12
}

.sidebar-colorful .color6 {
    background: #ef4836
}

.sidebar-colorful .color7 {
    background: #9a80b9
}

.sidebar-colorful .color8 {
    background: #a2ded0
}

.sidebar-colorful .color9 {
    background: #d2527f
}

.sidebar-colorful .color10 {
    background: #c78568
}

.sidebar-colorful .color11 {
    background: #e99844
}

.sidebar-square .sidebar-panel li a .icon {
    font-size: 14px;
    border-radius: 0;
    background: rgba(0,0,0,0.3)
}

.sidebar-no-icon .sidebar-panel li a {
    padding-left: 23px
}

    .sidebar-no-icon .sidebar-panel li a .icon {
        display: none
    }

.sidepanel {
    z-index: 9999;
    position: fixed;
    display: none;
    width: 300px;
    top: 60px;
    bottom: 0;
    right: 0;
    padding-top: 20px;
    overflow: auto;
    box-shadow: -1px 0 1px rgba(0,0,0,0.2);
    background: #FBFBFD
}

    .sidepanel ul {
        padding: 0
    }

    .sidepanel li {
        display: block
    }

        .sidepanel li a:hover {
            background: rgba(0,0,0,0.02)
        }

    .sidepanel .nav-tabs {
        font-size: 12px;
        font-weight: bold;
        display: block;
        width: 300px;
        position: fixed;
        background: #3d464d;
        z-index: 2;
        top: 60px;
        padding: 0
    }

        .sidepanel .nav-tabs > li {
            float: left;
            margin-bottom: -1px;
            display: inline-block;
            border-right: none;
            width: 33.3333%;
            margin: 0
        }

            .sidepanel .nav-tabs > li > a {
                margin-right: 0px;
                line-height: normal;
                color: #fff;
                display: block;
                padding: 10px 0;
                border: none;
                border-radius: 0px;
                text-align: center;
                margin-bottom: -1px;
                padding: 12px 0
            }

                .sidepanel .nav-tabs > li > a:hover {
                    border-color: none
                }

            .sidepanel .nav-tabs > li.active > a, .sidepanel .nav-tabs > li.active > a:hover, .sidepanel .nav-tabs > li.active > a:focus {
                color: #399BFF;
                cursor: default;
                background-color: #fff;
                border: none
            }

.sidepanel-m-title {
    text-align: center;
    font-family: 'Montserrat',sans-serif;
    font-size: 14px;
    color: #37363e;
    padding: 16px 0;
    position: relative
}

    .sidepanel-m-title .left-icon, .sidepanel-m-title .right-icon {
        border: 1px solid #e1e1e1;
        color: #58666e;
        position: absolute;
        width: 26px;
        font-size: 13px;
        height: 26px;
        border-radius: 3px
    }

        .sidepanel-m-title .left-icon a, .sidepanel-m-title .right-icon a {
            color: #58666e;
            display: block
        }

            .sidepanel-m-title .left-icon a:hover, .sidepanel-m-title .right-icon a:hover {
                opacity: 0.7
            }

    .sidepanel-m-title .left-icon {
        left: 0
    }

    .sidepanel-m-title .right-icon {
        right: 0
    }

.today {
    padding: 0
}

.list-w-title {
    margin: 0 -20px
}

    .list-w-title .label {
        font-family: 'Montserrat',sans-serif;
        font-size: 11px
    }

    .list-w-title .date {
        position: absolute;
        right: 20px;
        font-size: 11px;
        color: #76757B;
        top: 23px
    }

    .list-w-title a {
        display: block;
        position: relative;
        padding: 20px;
        border-bottom: 1px solid #E5E5E5;
        color: #7A8189
    }

    .list-w-title h4 {
        margin: 5px 0;
        font-size: 13px;
        font-family: 'Montserrat',sans-serif
    }

.gn-title {
    background: #E4E4E4;
    font-family: 'Montserrat',sans-serif;
    font-size: 12px;
    margin: 0 -20px;
    padding: 4px 0 4px 20px
}

.todo-list li {
    padding: 5px 0 5px 20px
}

#chat {
    position: relative
}

    #chat .group {
        font-size: 11px;
        margin: 0 -20px
    }

        #chat .group .member {
            position: relative;
            line-height: 17px
        }

            #chat .group .member a {
                display: block;
                padding: 10px 20px;
                color: #7A8893
            }

                #chat .group .member a:hover {
                    color: #3D464C
                }

            #chat .group .member img {
                border-radius: 50%;
                height: 36px;
                width: 36px;
                float: left;
                margin-right: 10px;
                margin-top: 0px
            }

            #chat .group .member b {
                font-weight: 600;
                display: block;
                font-size: 13px
            }

            #chat .group .member .status {
                display: block;
                position: absolute;
                right: 20px;
                top: 50%;
                bottom: 50%;
                margin: -4px 0;
                width: 8px;
                height: 8px;
                border-radius: 50%
            }

            #chat .group .member .online {
                background: #3CA63F
            }

            #chat .group .member .busy {
                background: #EA5944
            }

            #chat .group .member .away {
                background: #E99844
            }

            #chat .group .member .offline {
                background: #7B8994
            }

    #chat .search {
        padding: 20px;
        border-top: 1px solid #E5E5E5;
        background: #fff;
        position: fixed;
        bottom: 0;
        width: 300px;
        margin-bottom: 0;
        margin-left: -20px
    }

.content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 80px;
    margin-left: 250px;
    background: #f5f5f5
}

.footer {
    border-top: 1px solid #e2e2e2;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 0;
    padding: 10px;
    font-size: 11px;
    color: #666;
    background: rgba(0,0,0,0.02)
}

    .footer .col-md-6 {
        margin-bottom: 0
    }

.page-header {
    background: #fff;
    margin: -20px -30px 20px -30px;
    padding: 20px;
    border-bottom: none;
    position: relative
}

    .page-header .title {
        padding: 0;
        margin: 0;
        font-family: 'Montserrat',sans-serif;
        font-size: 20px;
        line-height: normal;
        font-weight: normal;
        color: #37363e;
        padding-bottom: 6px
    }

    .page-header .right {
        position: absolute;
        right: 20px;
        bottom: 20px;
        min-width: 500px;
        text-align: right
    }

        .page-header .right .btn-group {
            float: right
        }

        .page-header .right .btn {
            padding-left: 14px;
            padding-right: 14px;
            font-size: 13px
        }

            .page-header .right .btn .fa {
                margin: 0 4px
            }

            .page-header .right .btn .no-border {
                border: none
            }

        .page-header .right .btn-group .btn {
            border: 1px solid #E8EBED
        }

    .page-header .pagination {
        margin: 0
    }

    .page-header .widget-inline-list {
        right: 0;
        bottom: -25px;
        position: relative !important
    }

.loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #3d464d;
    opacity: 0.99
}

    .loading img {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        right: 50%;
        bottom: 50%;
        top: 50%;
        margin: -20px
    }

.presentation {
    background: #e2010f;
    margin: -20px -30px 30px -30px;
    padding: 20px 30px
}

    .presentation .titles .icon {
        color: #fff;
        border-radius: 3px;
        font-size: 36px;
        line-height: 0;
        padding: 8px 16px;
        text-align: center
    }

        .presentation .titles .icon .fa {
            font-weight: inherit
        }

    .presentation .titles h1 {
        font-size: 38px;
        font-weight: 600;
        font-family: 'Montserrat',sans-serif;
        margin: 30px 0 0px 0;
        color: #ffffff;
    }

    .presentation .titles h4 {
        font-size: 24px;
        font-weight: 300;
        color: #c7c7c7;
        margin: 0px
    }

    .presentation .list {
        display: inline-block;
        float: right;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 2em;
        color: #37363e;
        right: 0
    }

        .presentation .list a {
            border-bottom: 1px solid #ccc
        }

        .presentation .list .fa {
            margin-right: 10px;
            color: #26a65b
        }

.fa-item {
    padding: 6px 0;
    position: relative;
    padding-left: 50px;
    font-size: 13px;
    color: #37363e;
    border-radius: 3px;
    border: 5px solid #fff
}

    .fa-item .fa {
        position: absolute;
        left: 10px;
        font-size: 20px;
        color: #444;
        display: block;
        width: 40px;
        text-align: center
    }

    .fa-item:hover {
        color: #000;
        background: #f2f2f2;
        border-color: #f2f2f2
    }

.colorsheme {
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 3px;
    float: left;
    width: 30px;
    height: 30px
}

.kode-alert {
    color: #fff;
    position: relative;
    border-radius: 3px;
    text-align: left;
    margin-bottom: 10px;
    padding: 12px;
    padding-right: 30px
}

    .kode-alert a {
        color: inherit;
        text-decoration: underline;
        font-weight: 600
    }

    .kode-alert h4 {
        font-size: 14px;
        margin: 0;
        color: inherit;
        font-weight: 600;
        line-height: normal
    }

    .kode-alert .img {
        width: 40px;
        height: 40px;
        position: absolute;
        border-radius: 3px;
        left: 12px;
        top: 12px
    }

.kode-alert-img {
    padding-left: 65px;
    min-height: 64px
}

.kode-alert-icon {
    padding-left: 40px
}

    .kode-alert-icon .fa {
        display: block;
        width: 20px;
        text-align: center;
        position: absolute;
        font-size: 15px;
        left: 12px;
        top: 17px
    }

.kode-alert .closed {
    position: absolute;
    right: 3px;
    text-decoration: none;
    font-weight: bold;
    top: 0px;
    font-size: 20px;
    color: rgba(255,255,255,0.5);
    padding: 4px
}

    .kode-alert .closed:hover {
        color: #fff
    }

.kode-alert-click {
    cursor: pointer;
    padding-right: 12px
}

.kode-alert .primary {
    outline: 0;
    border: none;
    background: rgba(0,0,0,0.4);
    color: inherit;
    border-radius: 3px;
    padding: 4px 10px
}

.kode-alert .cancel {
    outline: 0;
    border: none;
    background: rgba(255,255,255,0.4);
    color: rgba(0,0,0,0.8);
    border-radius: 3px;
    padding: 4px 10px
}

    .kode-alert .primary:hover, .kode-alert .cancel:hover {
        opacity: 0.9
    }

.kode-alert-top, .kode-alert-bottom, .kode-alert-top-left, .kode-alert-top-right, .kode-alert-bottom-left, .kode-alert-bottom-right, .kode-alert-fullscreen {
    box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
    position: fixed;
    display: none;
    z-index: 1000
}

.kode-alert-top {
    top: 0;
    left: 0;
    right: 0
}

.kode-alert-bottom {
    bottom: 0;
    left: 0;
    right: 0
}

.kode-alert-top-left {
    top: 80px;
    left: 20px
}

.kode-alert-top-right {
    top: 80px;
    right: 20px
}

.kode-alert-bottom-left {
    bottom: 20px;
    left: 20px
}

.kode-alert-bottom-right {
    bottom: 20px;
    right: 20px
}

.kode-alert-fullsize {
    top: 50%;
    left: 50%;
    margin: -20px
}

.alert1 {
    background: #399bff
}

.alert2 {
    background: #33577b
}

.alert3 {
    background: #26a65b
}

.alert4 {
    background: #51b7a3
}

.alert5 {
    background: #f39c12
}

.alert6 {
    background: #ef4836
}

.alert7 {
    background: #9a80b9
}

.alert8 {
    background: #a2ded0;
    color: #444
}

    .alert8 .closed {
        color: inherit
    }

    .alert8 a:hover.closed {
        color: inherit
    }

.alert9 {
    background: #d2527f
}

.alert10 {
    background: #c78568
}

.alert11 {
    background: #e99844
}

.alert1-light {
    background: #d2e8ff;
    color: #00356c
}

.alert2-light {
    background: #7ea3c9;
    color: #152433
}

.alert3-light {
    background: #83e3aa;
    color: #13532e
}

.alert4-light {
    background: #bde4dc;
    color: #2f7365
}

.alert5-light {
    background: #fad9a4;
    color: #976008
}

.alert6-light {
    background: #fac9c4;
    color: #b11e0e
}

.alert7-light {
    background: #e8e3ef;
    color: #674b88
}

.alert8-light {
    background: #edf9f6;
    color: #2f8571
}

.alert9-light {
    background: #f2cbd9;
    color: #97274e
}

.alert10-light {
    background: #f0dfd8;
    color: #935336
}

.alert11-light {
    background: #f9e3cd;
    color: #b26515
}

    .alert1-light .closed, .alert2-light .closed, .alert3-light .closed, .alert4-light .closed, .alert5-light .closed, .alert6-light .closed, .alert7-light .closed, .alert8-light .closed, .alert9-light .closed, .alert10-light .closed, .alert11-light .closed {
        color: inherit;
        opacity: 0.6
    }

        .alert1-light .closed:hover {
            color: inherit;
            opacity: 0.8
        }

.mailbox .write {
    padding: 20px;
    background: #fff
}

.mailbox-menu {
    background: #fff;
    overflow: hidden;
    border-top: 1px solid rgba(0,0,0,0.08);
    border-bottom: 1px solid rgba(0,0,0,0.08);
    position: relative
}

    .mailbox-menu .menu {
        padding: 11px 0;
        margin-bottom: 0
    }

        .mailbox-menu .menu li {
            display: inline;
            color: #76747A;
            font-size: 13px;
            padding: 16px 0;
            border-right: 1px solid rgba(0,0,0,0.08)
        }

            .mailbox-menu .menu li .fa {
                font-size: 16px;
                color: #666;
                margin-right: 5px
            }

            .mailbox-menu .menu li a {
                color: #76747A;
                border-radius: 3px;
                padding: 15px 19px
            }

                .mailbox-menu .menu li a:hover {
                    color: #333
                }

                    .mailbox-menu .menu li a:hover .fa {
                        color: #333
                    }

            .mailbox-menu .menu li:last-child {
                margin-left: -1px
            }

.mailbox-inbox {
    background: #fff;
    border-right: 1px solid #ddd;
    padding: 0;
    left: 0;
    top: 0;
    color: #76747A
}

    .mailbox-inbox li {
        display: block
    }

    .mailbox-inbox .item {
        display: block;
        padding: 20px;
        border-bottom: 1px solid rgba(0,0,0,0.09);
        color: #76747A;
        position: relative;
        font-size: 12px
    }

        .mailbox-inbox .item:hover {
            background: #E3F2FF
        }

            .mailbox-inbox .item:hover .from {
                color: #37363E
            }

    .mailbox-inbox .img {
        width: 40px;
        height: 40px;
        border-radius: 999px;
        float: left;
        margin-right: 10px
    }

    .mailbox-inbox .from {
        font-size: 13px;
        display: block;
        color: #333
    }

    .mailbox-inbox .date {
        position: absolute;
        font-size: 10px;
        right: 20px;
        top: 20px;
        opacity: 0.7
    }

    .mailbox-inbox .search {
        padding: 23px 20px;
        position: relative;
        border-bottom: 1px solid rgba(0,0,0,0.08)
    }

    .mailbox-inbox .mailbox-search {
        border-radius: 999px;
        border: none;
        height: 32px;
        width: 100%;
        padding-left: 20px;
        padding-right: 36px;
        background: rgba(0,0,0,0.07);
        color: #333;
        box-shadow: inset none
    }

        .mailbox-inbox .mailbox-search:focus {
            background: rgba(0,0,0,0.12);
            box-shadow: none;
            border-top: none
        }

    .mailbox-inbox .searchbutton {
        right: 35px;
        top: 27px
    }

.panel-widget .mailbox-inbox {
    border: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px
}

.container-mailbox {
    position: relative
}

.chat {
    border-left: 1px solid #ddd;
    margin-left: -1px
}

    .chat .title {
        height: 79px;
        padding: 12px 20px;
        background: #fff;
        border-bottom: 1px solid rgba(0,0,0,0.08);
        position: relative
    }

        .chat .title h1 {
            margin: 0;
            font-size: 19px
        }

        .chat .title .btn-group {
            position: absolute;
            right: 20px;
            bottom: 20px
        }

    .chat .conv {
        padding: 20px;
        background: #fff;
        margin-bottom: 0
    }

        .chat .conv li {
            display: block;
            position: relative;
            padding-left: 70px;
            margin-bottom: 20px
        }

        .chat .conv .img {
            border-radius: 999px;
            width: 50px;
            height: 50px;
            position: absolute;
            left: 0;
            top: 0
        }

        .chat .conv .ballon {
            display: inline-table;
            padding: 14px 20px;
            border-radius: 20px;
            margin-bottom: 14px
        }

        .chat .conv .color1 {
            background: #B9F98E;
            color: #0A451D
        }

        .chat .conv .color2 {
            background: #B6DEFF;
            color: #153440
        }

        .chat .conv .addition {
            padding: 5px 20px;
            background: #f2f2f2;
            display: inline-block;
            border-radius: 20px;
            border: 1px dashed #ccc;
            font-size: 12px
        }

            .chat .conv .addition a {
                text-decoration: underline
            }

            .chat .conv .addition .fa {
                margin-right: 6px;
                font-size: 14px
            }

        .chat .conv .date {
            text-align: center;
            border-top: 1px solid #f2f2f2;
            border-bottom: 1px solid #f2f2f2;
            padding: 10px 0;
            font-size: 11px
        }

.changelogs .title {
    font-weight: 600
}

.changelogs .version {
    font-size: 13px;
    font-family: 'Montserrat',sans-serif
}

.changelogs .date {
    font-size: 12px;
    position: absolute;
    right: 20px;
    top: 20px
}

.changelogs .update {
    position: relative;
    background: #fff;
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    margin-bottom: 20px
}

.changelogs .list {
    font-size: 12px;
    margin-top: 10px;
    background: #f2f2f2;
    padding: 6px 10px;
    border-radius: 3px;
    border-left: 2px solid #ccc
}

    .changelogs .list h4 {
        margin: 0px;
        font-size: 14px
    }

    .changelogs .list:hover {
        background: #eee
    }

.social-top {
    height: 250px;
    padding: 20px 30px;
    border-bottom: 60px solid #fff;
    position: relative;
    background: url(../img/socialbg.jpg);
    background-size: cover
}

    .social-top .profile-img {
        width: 120px;
        height: 120px;
        border-radius: 999px;
        border: 4px solid #fff
    }

    .social-top .name {
        margin: 0;
        color: #fff;
        bottom: 5px;
        position: absolute;
        left: 140px;
        top: 10px;
        font-size: 28px;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5)
    }

        .social-top .name .btn {
            margin-left: 6px;
            text-shadow: none
        }

    .social-top .profile-text {
        position: absolute;
        margin: 0;
        height: 25px;
        overflow: hidden;
        font-size: 16px;
        top: 85px;
        left: 140px
    }

    .social-top .profile-left {
        position: absolute;
        width: 90%;
        bottom: -52px
    }

.social-stats {
    padding: 0;
    margin: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    right: 20px;
    bottom: 0;
    color: rgba(0,0,0,0.8);
    position: absolute;
    background: rgba(255,255,255,0.6)
}

    .social-stats li {
        display: inline-block;
        width: 31.33333%;
        font-size: 12px;
        padding: 10px 20px;
        text-align: center
    }

        .social-stats li b {
            display: block;
            font-weight: 600;
            font-size: 20px
        }

.social-content {
    padding: 20px 30px
}

.status {
    margin: -20px
}

    .status .who {
        padding: 20px;
        border-bottom: 1px solid rgba(0,0,0,0.07)
    }

        .status .who img {
            border-radius: 999px;
            width: 40px;
            height: 40px;
            float: left;
            margin-right: 10px
        }

        .status .who .name {
            display: block;
            font-size: 13px;
            font-weight: normal;
            line-height: normal
        }

            .status .who .name b {
                color: #399bff
            }

        .status .who .from {
            font-size: 12px;
            opacity: 0.7
        }

    .status .text {
        padding: 20px;
        font-size: 13px;
        color: #37363e;
        line-height: 1.82em
    }

    .status .image {
        padding: 20px
    }

        .status .image img {
            width: 100%
        }

    .status .links {
        margin: 0;
        padding: 15px 20px;
        border-top: 1px solid rgba(0,0,0,0.07)
    }

        .status .links .fa {
            font-size: 14px;
            margin-right: 2px;
            color: inherit
        }

        .status .links li {
            display: inline;
            padding-right: 20px
        }

    .status .comments {
        padding: 0 20px;
        font-size: 13px;
        border-top: 1px solid rgba(0,0,0,0.07)
    }

        .status .comments li {
            display: block;
            position: relative;
            padding: 15px 0 15px 40px;
            border-bottom: 1px solid rgba(0,0,0,0.07)
        }

            .status .comments li:last-child {
                border-bottom: none;
                padding-bottom: 5px
            }

        .status .comments img {
            width: 30px;
            height: 30px;
            border-radius: 999px;
            position: absolute;
            left: 0px
        }

        .status .comments .name {
            color: #58666e;
            display: block;
            font-weight: 600
        }

.invoice {
    background: #fff;
    position: relative;
    padding: 70px;
    border: 1px solid #ccc;
    font-family: 'Montserrat',sans-serif
}

    .invoice .logo {
        line-height: 26px;
        text-align: center;
        color: #76747A;
        font-weight: 300;
        font-family: 'Roboto',sans-serif;
        font-size: 16px
    }

        .invoice .logo img {
            margin-bottom: 10px
        }

        .invoice .logo b {
            color: #37353E;
            font-weight: 500
        }

    .invoice .line {
        border-bottom: 2px solid #E4E4E4;
        padding: 50px 0
    }

        .invoice .line h4 {
            margin: 0;
            color: #76747A;
            font-weight: bold;
            font-size: 14px
        }

        .invoice .line h2 {
            color: #37353E;
            font-weight: normal;
            font-size: 24px;
            margin: 0
        }

    .invoice .table {
        margin-top: 40px;
        color: #37353E;
        font-size: 17px
    }

        .invoice .table .title {
            font-size: 14px;
            color: #76747A
        }

        .invoice .table p {
            font-family: 'Roboto',sans-serif;
            font-size: 14px;
            color: #76747A
        }

        .invoice .table .total {
            margin: 0
        }

        .invoice .table > thead > tr > th, .invoice .table > tbody > tr > th, .invoice .table > tfoot > tr > th, .invoice .table > thead > tr > td, .invoice .table > tbody > tr > td, .invoice .table > tfoot > tr > td {
            padding-left: 0px;
            padding-right: 0px
        }

    .invoice .signature {
        border-bottom: 2px solid #E4E4E4;
        border-top: 2px solid #E4E4E4;
        margin: 20px 0;
        padding: 20px 0
    }

    .invoice .bottomtext {
        font-size: 11px;
        font-family: 'Roboto',sans-serif
    }

    .invoice .invoicename {
        color: #E4E4E4;
        font-weight: bold;
        font-size: 30px;
        right: 50px;
        top: 50px;
        position: absolute
    }

.login-form {
    width: 100%;
    padding: 0px 0px 0px 0px;
    text-shadow: none
}

    .login-form form {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 3px
    }

        .login-form form img {
            margin-bottom: 18px
        }

        .login-form form .profile {
            border-radius: 999px
        }

        .login-form form .top {
            border-bottom: 1px solid #ddd;
            text-align: center;
            padding: 30px 0
        }

            .login-form form .top .icon {
                width: 100px;
                height: 100px
            }

            .login-form form .top h1, .login-form form .top h4 {
                margin: 0
            }

            .login-form form .top h1 {
                color: #37363E;
                font-size: 30px;
                font-family: 'Montserrat',sans-serif;
                font-weight: bold;
                margin-top: -14px
            }

            .login-form form .top h4 {
                font-weight: normal;
                color: #76757B;
                font-size: 15px
            }

        .login-form form .form-area {
            padding: 40px
        }

            .login-form form .form-area .group {
                position: relative;
                margin-bottom: 20px
            }

            .login-form form .form-area .form-control {
                padding-left: 38px;
                height: 40px
            }

            .login-form form .form-area .fa {
                position: absolute;
                top: 11px;
                left: 13px;
                font-size: 16px;
                color: #C3C3C3
            }

            .login-form form .form-area .btn {
                height: 42px;
                font-weight: 600
            }

            .login-form form .form-area .checkbox {
                margin-bottom: 20px
            }

    .login-form .footer-links {
        color: #76757B;
        padding: 10px 5px
    }

        .login-form .footer-links a {
            color: #76757B
        }

            .login-form .footer-links a:hover {
                color: #37363e
            }

.error-pages {
    text-align: center;
    padding-top: 100px
}

    .error-pages .icon {
        border-radius: 4px;
        margin-bottom: 20px
    }

    .error-pages h1 {
        color: #37363e;
        font-family: 'Montserrat',sans-serif;
        font-size: 28px
    }

    .error-pages h4 {
        color: #767279;
        font-weight: normal;
        font-size: 16px;
        margin-top: 10px
    }

    .error-pages form {
        width: 400px;
        margin: 0px auto;
        margin-top: 30px;
        position: relative
    }

        .error-pages form .form-control {
            padding-left: 34px;
            height: 40px
        }

        .error-pages form .fa {
            position: absolute;
            left: 10px;
            top: 11px;
            font-size: 16px
        }

    .error-pages .bottom-links {
        margin-top: 30px
    }

        .error-pages .bottom-links a {
            margin: 0px 6px
        }

.topstats {
    background: #fff;
    padding: 0;
    color: #76747A;
    position: relative;
    font-size: 12px;
    border-radius: 3px;
    margin-left: -5px;
    margin-right: -5px;
    text-shadow: none;
    padding: 12px 0
}

    .topstats li {
        display: block;
        text-align: center;
        margin: 10px 0
    }

    .topstats .title {
        color: #37363e;
        font-weight: 600;
        font-size: 13px
    }

        .topstats .title .fa {
            font-size: 15px;
            color: #000;
            margin-right: 4px;
            opacity: 0.4
        }

    .topstats h3 {
        font-size: 28px;
        font-weight: bold;
        font-family: 'Montserrat',sans-serif;
        letter-spacing: -1px;
        line-height: normal;
        margin: 1px 0
    }

        .topstats h3 small {
            color: #37363e
        }

    .topstats .diff b {
        font-weight: bold
    }

    .topstats .diff .fa {
        margin-right: 2px
    }

    .topstats .arrow {
        position: absolute;
        width: 0;
        height: 0;
        top: -18px;
        right: 5px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #fff transparent
    }

.flotchart-container {
    box-sizing: border-box;
    width: 100%
}

.flotchart-placeholder {
    width: 100%;
    height: 300px;
    font-size: 13px;
    line-height: 1.2em
}

.legend table {
    border-spacing: 5px
}

.easypie {
    display: inline-block;
    width: 95px;
    font-size: 12px;
    height: 95px;
    margin: 0 20px 20px 0px;
    vertical-align: top;
    position: relative;
    text-align: center;
    padding-top: 29px;
    border-radius: 999px
}

    .easypie span {
        display: block;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Montserrat',sans-serif
    }

    .easypie canvas {
        position: absolute;
        left: 0;
        width: 95px;
        height: 95px;
        top: 0
    }

.rickshaw-chart {
    width: 100%
}

.demo-sidebarmenu .sidebar {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    top: 0;
}

    .demo-sidebarmenu .sidebar .panel {
        position: relative
    }

.calendar-layout {
    background: #fff;
    margin: -20px -30px;
    margin-bottom: 0;
    padding: 30px 20px
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #399bff;
    border-color: #399bff
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
    color: #fff
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
    background-color: #ef4836;
    border-color: #ef4836
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
    color: #fff
}

.checkbox-info input[type="checkbox"]:checked + label::before {
    background-color: #51b7a3;
    border-color: #51b7a3
}

.checkbox-info input[type="checkbox"]:checked + label::after {
    color: #fff
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
    background-color: #f39c12;
    border-color: #f39c12
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
    color: #fff
}

.checkbox-success input[type="checkbox"]:checked + label::before {
    background-color: #26a65b;
    border-color: #26a65b
}

.checkbox-success input[type="checkbox"]:checked + label::after {
    color: #fff
}

.radio-primary input[type="radio"] + label::after {
    background-color: #399bff
}

.radio-primary input[type="radio"]:checked + label::before {
    border-color: #399bff
}

.radio-primary input[type="radio"]:checked + label::after {
    background-color: #399bff
}

.radio-danger input[type="radio"] + label::after {
    background-color: #ef4836
}

.radio-danger input[type="radio"]:checked + label::before {
    border-color: #ef4836
}

.radio-danger input[type="radio"]:checked + label::after {
    background-color: #ef4836
}

.radio-info input[type="radio"] + label::after {
    background-color: #51b7a3
}

.radio-info input[type="radio"]:checked + label::before {
    border-color: #51b7a3
}

.radio-info input[type="radio"]:checked + label::after {
    background-color: #51b7a3
}

.radio-warning input[type="radio"] + label::after {
    background-color: #f39c12
}

.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f39c12
}

.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f39c12
}

.radio-success input[type="radio"] + label::after {
    background-color: #26a65b
}

.radio-success input[type="radio"]:checked + label::before {
    border-color: #26a65b
}

.radio-success input[type="radio"]:checked + label::after {
    background-color: #26a65b
}

a:focus, input, input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: 0px
}

mark {
    background: #FBDDAC;
    padding: 2px 4px;
    border-radius: 3px
}

code {
    background: #EDF6FF;
    padding: 2px 2px;
    color: #000;
    border: 1px solid #C3E1FF
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: inherit;
    font-weight: 20;
    line-height: 1;
    color: #37363e
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 10px;
    margin-bottom: 5px;
    letter-spacing: -0.02em
}

h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 15px;
    margin-bottom: 10px
}

h1, .h1 {
    font-size: 2.25em
}

h2, .h2 {
    font-size: 2em
}

h3, .h3 {
    font-size: 1.75em
}

h4, .h4 {
    font-size: 1.5em
}

h5, .h5 {
    font-size: 1.25em
}

h6, .h6 {
    font-size: 1em
}

.text-st {
    color: #666
}

.lead {
    line-height: 1.7em
}

blockquote {
    background: #f2f2f2;
    padding: 16px 20px;
    border-radius: 3px;
    border-left: 5px solid #ccc
}

    blockquote footer, blockquote small, blockquote .small {
        display: block;
        font-size: 80%;
        line-height: 1.42857143;
        color: inherit;
        opacity: 0.6
    }

    .blockquote-reverse, blockquote.pull-right {
        border-right: 5px solid #ccc
    }

dt, dd {
    line-height: inherit
}

.font-title {
    font-family: 'Montserrat',sans-serif
}

.font-w-300 {
    font-weight: 300
}

.font-w-400 {
    font-weight: 400
}

.font-w-600 {
    font-weight: 600
}

.font-w-700 {
    font-weight: 700
}

.font-w-800 {
    font-weight: 800
}

.font-title-tab {
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px
}

.btn {
    font-size: 14px;
    border: none;
    background: #e4e4e4;
    color: inherit;
    border-radius: 3px
}

    .btn:hover {
        background-color: #b1b1b1;
        color: #fff
    }

    .btn:active, .btn.active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
        pointer-events: none;
        cursor: not-allowed;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: .65
    }

.cursor {
    cursor: pointer;
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65
}

.btn-xs {
    font-size: 11px;
    padding: 3px 8px
}

.btn-sm {
    font-size: 12px;
    padding: 5px 14px
}

.btn-lg {
    font-size: 16px;
    padding: 10px 30px
}

.btn-xl {
    font-size: 20px;
    padding: 14px 30px
}

.btn .fa {
    font-size: 15px;
    margin-right: 5px
}

.btn-icon {
    padding-left: 10px;
    padding-right: 10px
}

    .btn-icon .fa {
        margin: 0;
    }

.btn-rounded {
    border-radius: 999px
}

.btn-square {
    border-radius: 0
}

.btndiv .btn {
    margin-bottom: 10px
}

.btn-default {
    background-color: #399bff;
    color: #fff
}

    .btn-default .badge {
        color: #399bff;
        background-color: #fff
    }

.btn-primary {
    background-color: #33577b;
    color: #fff
}

    .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
        background-color: #396189;
        color: #fff
    }

    .btn-primary .badge {
        color: #33577b;
        background-color: #fff
    }

.btn-white {
    background-color: #e4e4e4;
    color: #666
}

    .btn-white .badge {
        color: #e4e4e4;
        background-color: #fff
    }

.btn-toggle {
    background-color: #e4e4e4;
    color: #666;
}

    .btn-toggle .badge {
        color: #e4e4e4;
        background-color: #fff
    }

.btn-light {
    background-color: #fff;
    color: inherit;
    border: 1px solid #BDC4C9
}

.btn-success {
    background-color: #98030d;
    color: #fff
}

    .btn-success .badge {
        color: #26a65b;
        background-color: #fff
    }

.btn-info {
    background-color: #98030d;
    color: #fff;
}

    .btn-info .badge {
        color: #51b7a3;
        background-color: #fff
    }

.btn-warning {
    background-color: #f39c12;
    color: #fff
}

    .btn-warning .badge {
        color: #f39c12;
        background-color: #fff
    }

.btn-danger {
    background-color: #ef4836;
    color: #fff
}

    .btn-danger .badge {
        color: #ef4836;
        background-color: #fff
    }

.btn-option1 {
    background-color: #9a80b9;
    color: #fff
}

    .btn-option1 .badge {
        color: #9a80b9;
        background-color: #fff
    }

.btn-option2 {
    background-color: #a2ded0;
    color: #333
}

    .btn-option2 .badge {
        color: #a2ded0;
        background-color: #fff
    }

.btn-option3 {
    background-color: #d2527f;
    color: #fff
}

    .btn-option3 .badge {
        color: #d2527f;
        background-color: #fff
    }

.btn-option4 {
    background-color: #c78568;
    color: #fff
}

    .btn-option4:active, .btn-option4.active, .open > .dropdown-toggle.btn-option4 {
        background: #c07655;
        box-shadow: none;
        color: #fff
    }

    .btn-option4 .badge {
        color: #c78568;
        background-color: #fff
    }

.btn-option5 {
    background-color: #e99844;
    color: #fff
}

    .btn-option5 .badge {
        color: #e99844;
        background-color: #fff
    }

.label {
    font-size: inherit;
    padding: 1px 6px;
    font-weight: 600;
    border-radius: 4px
}

.label-default {
    background: #399bff
}

.label-primary {
    background: #33577b
}

.label-success {
    background: #26a65b
}

.label-info {
    background: #51b7a3
}

.label-warning {
    background: #f39c12
}

.label-danger {
    background: #ef4836
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
    color: #26a65b
}

.has-success .form-control {
    border-color: #26a65b;
    box-shadow: none
}

    .has-success .form-control:focus {
        border-color: #26a65b;
        box-shadow: none
    }

.has-success .input-group-addon {
    color: #26a65b;
    background-color: #26a65b;
    border-color: #26a65b
}

.has-success .form-control-feedback {
    color: #26a65b
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
    color: #f39c12
}

.has-warning .form-control {
    border-color: #f39c12;
    box-shadow: none
}

    .has-warning .form-control:focus {
        border-color: #f39c12;
        box-shadow: none
    }

.has-warning .input-group-addon {
    color: #f39c12;
    background-color: #f39c12;
    border-color: #f39c12
}

.has-warning .form-control-feedback {
    color: #f39c12
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
    color: #ef4836
}

.has-error .form-control {
    border-color: #ef4836;
    box-shadow: none
}

    .has-error .form-control:focus {
        border-color: #ef4836;
        box-shadow: none
    }

.has-error .input-group-addon {
    color: #ef4836;
    background-color: #ef4836;
    border-color: #ef4836
}

.has-error .form-control-feedback {
    color: #ef4836
}

input, select {
    height: 34px;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #BDC4C9;
    box-shadow: inset 0px 1px 0px #F1F0F1
}

.form-control {
    height: 34px;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #BDC4C9;
    display: block;
    box-shadow: inset 0px 1px 0px #F1F0F1
}

    .form-control:focus {
        background: #f7f7f7;
        border-color: #BDC4C9;
        box-shadow: none;
        border-top: 1px solid #B7B7B7
    }

.form-label {
    font-weight: 500
}

.fieldset-form fieldset {
    padding: 20px;
    margin: 0 2px;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding-top: 10px
}

.fieldset-form legend {
    width: auto;
    padding: 0px 10px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: inherit;
    color: #333;
    border: 0;
    font-weight: 600;
    border-bottom: none
}

.form-control-line {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    padding-left: 0;
    border-radius: 0;
    box-shadow: none
}

    .form-control-line:focus {
        border-top: 0;
        background: none;
        border-color: #666
    }

.form-control-radius {
    border-radius: 999px
}

.form-group {
    margin-bottom: 18px
}

.form-inline label {
    margin-right: 5px
}

.form-inline .checkbox-inline, .form-inline .radio-inline {
    padding-left: 0;
    margin-right: 5px
}

    .form-inline .checkbox-inline label, .form-inline .radio-inline label {
        padding-left: 5px
    }

.form-inline .form-group {
    padding-right: 10px
}

.input-group-addon {
    background: #f7f7f7;
    border-color: #BDC4C9;
    font-weight: 600;
    padding-left: 17px;
    padding-right: 17px
}

.input-sm {
    height: 30px;
    font-size: 12px;
    line-height: 1.5
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 3px
}

.breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
    font-weight: 600
}

    .breadcrumb .active {
        font-weight: normal;
        color: #999
    }

.dropdown-header {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 10px;
    padding-left: 15px;
    font-family: 'Montserrat',sans-serif;
    padding-top: 5px
}

.dropdown-menu li {
    position: relative
}

.dropdown-menu > li > a {
    padding: 9px 20px;
    color: #3D464D
}

.dropdown-menu .divider {
    margin: 4px 0
}

.dropdown-menu-list li a {
    padding-left: 40px
}

.dropdown-menu-list .badge {
    right: 20px;
    font-weight: normal;
    margin-left: 5px;
    padding: 2px 6px;
    font-size: 11px
}

.dropdown-menu-list .falist {
    position: absolute;
    left: 15px;
    top: 11px;
    font-size: 15px
}

#editor {
    max-height: 250px;
    height: 250px;
    background-color: white;
    border-collapse: separate;
    border: 1px solid #ccc;
    padding: 4px;
    box-sizing: content-box;
    -webkit-box-shadow: rgba(0,0,0,0.0745098) 0 1px 1px 0 inset;
    box-shadow: rgba(0,0,0,0.0745098) 0 1px 1px 0 inset;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    overflow: scroll;
    outline: none
}

#voiceBtn {
    width: 20px;
    color: transparent;
    background-color: transparent;
    transform: scale(2, 2);
    -webkit-transform: scale(2, 2);
    -moz-transform: scale(2, 2);
    border: transparent;
    cursor: pointer;
    box-shadow: none;
    -webkit-box-shadow: none
}

div[data-role="editor-toolbar"] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.dropdown-menu a {
    cursor: pointer
}

.tab-content {
    background: #fff;
    padding: 20px
}

.nav-tabs {
    border-bottom: none;
    background: #E9E9E9;
    padding: 0
}

    .nav-tabs > li {
        float: left;
        margin-bottom: -1px;
        margin-right: -2px
    }

        .nav-tabs > li > a {
            margin-right: 2px;
            line-height: 1.42857143;
            color: inherit;
            border: none;
            border-radius: 0
        }

            .nav-tabs > li > a:hover {
                border-color: none;
                background: rgba(0,0,0,0.1)
            }

        .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
            color: #555;
            cursor: default;
            background-color: #fff;
            border: none;
            border-bottom-color: transparent
        }

.nav-pills {
    padding-bottom: 10px
}

    .nav-pills > li {
        float: left
    }

        .nav-pills > li > a {
            border-radius: 3px;
            padding: 4px 10px
        }

        .nav-pills > li + li {
            margin-left: 2px
        }

        .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
            color: #fff;
            background-color: #399bff
        }

.nav-stacked li {
    width: 100%;
    display: block
}

.nav-justified {
    padding: 0;
    margin-bottom: -1px;
    border-bottom: none
}

@media (min-width:768px) {
    .nav-tabs.nav-justified > li > a {
        margin-bottom: 0
    }
}

.nav-tabs.nav-justified > li > a {
    border-radius: 0px
}

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
    border: none
}

@media (min-width:768px) {
    .nav-tabs.nav-justified > li > a {
        border-bottom: 1px solid #fff;
    }
}

.nav-line {
    border-bottom: none;
    background: none;
    padding: 0
}

    .nav-line > li {
        float: left;
        margin-bottom: -1px
    }

        .nav-line > li > a:hover {
            border-color: none;
            background: none;
            border-bottom: 3px solid #e4e4e4;
            color: #37363e
        }

        .nav-line > li.active > a, .nav-line > li.active > a:hover, .nav-line > li.active > a:focus {
            color: inherit;
            cursor: default;
            background-color: transparent;
            border-bottom: 3px solid #399bff
        }

.nav-icon .fa {
    font-size: 16px;
    color: inherit
}

.nav-pills > li > a {
    border-radius: 3px;
    padding: 4px 14px
}

.tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li {
    float: none;
    margin: 0
}

    .tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a {
        min-width: 74px;
        margin-right: 0;
        margin-bottom: 3px
    }

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 29px
}

.tabs-right > .nav-tabs {
    float: right;
    margin-left: 29px;
    text-align: right
}

.tabcolor5-bg li a, .tabcolor6-bg li a, .tabcolor7-bg li a, .tabcolor8-bg li a, .tabcolor9-bg li a, .tabcolor10-bg li a {
    color: #fff
}

.tabcolor5-bg {
    background: #399bff
}

.tabcolor6-bg {
    background: #33577b
}

.tabcolor7-bg {
    background: #26a65b
}

.tabcolor8-bg {
    background: #51b7a3
}

.tabcolor9-bg {
    background: #f39c12
}

.tabcolor10-bg {
    background: #ef4836
}

.panel {
    box-shadow: none;
    border: 1px solid #e5e5e5;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    position: relative
}

.panel-footer {
    margin: -20px;
    margin-top: 20px;
    background: #f9f9f9
}

.panel-transparent {
    background: none;
    border: none
}

    .panel-transparent .panel-title {
        background: none;
        border: none
    }

.panel-default > .panel-heading {
    background-color: inherit;
    border-bottom: 0;
    color: #3D464D
}

.panel-title {
    font-family: 'Montserrat',sans-serif;
    color: #58666e;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 16px 20px;
    margin: -20px;
    background: #fff;
    margin-bottom: 5px;
    border-bottom: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel-body, .panel-heading {
    padding: 0;
    color: inherit;
    background-color: transparent;
    border-color: none
}

.panel-heading {
    background: transparent;
    border-bottom: transparent
}

.panel .badge {
    font-size: 11px;
    font-family: 'Roboto',sans-serif;
    text-transform: none;
    padding: 4px 10px;
    margin-left: 5px;
    font-weight: normal
}

.panel-footer {
    background: rgba(0,0,0,0.01);
    border-top: 1px solid inherit
}

.panel-title .badge {
    color: #fff;
    background-color: rgba(0,0,0,0.2)
}

.panel-heading {
    font-size: 20px;
    padding-bottom: 15px;
    font-weight: 300
}

.panel .list-group {
    margin: -20px;
    margin-top: 20px;
    background: transparent
}

    .panel .list-group li {
        border: 1px solid rgba(255,255,255,0.2);
        border-left: 0;
        background: transparent;
        border-right: 0
    }

.panel .list-group-item:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px
}

.panel .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom: 0
}

.panel-collapse {
    padding-bottom: 0
}

    .panel-collapse .panel-title {
        padding-bottom: 0;
        margin-bottom: 0
    }

    .panel-collapse .panel-body {
        padding: 10px 0 20px 0
    }

.panel-widget {
    overflow: hidden;
    border: none
}

.panel-default {
    border-color: #ddd
}

    .panel-default .badge {
        color: inherit;
        background-color: rgba(0,0,0,0.2)
    }

    .panel-default .list-group {
        margin: -20px;
        margin-top: 20px
    }

        .panel-default .list-group li {
            border: 1px solid #ddd;
            border-left: 0;
            border-right: 0
        }

    .panel-default .list-group-item:first-child {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px
    }

    .panel-default .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom: 0
    }

.panel-primary {
    border-color: #399bff;
    background: #399bff;
    color: #fff
}

    .panel-primary > .panel-heading {
        color: inherit;
        background: transparent;
        border-bottom: transparent
    }

        .panel-primary > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #399bff
        }

    .panel-primary .panel-title {
        color: #fff;
        background: #399bff;
        border-bottom: 1px solid rgba(255,255,255,0.1)
    }

    .panel-primary .panel-footer {
        border-top: 1px solid rgba(255,255,255,0.1);
        background: rgba(255,255,255,0.04)
    }

    .panel-primary > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #399bff
    }

.panel-success {
    border-color: #26a65b;
    background: #26a65b;
    color: #fff
}

    .panel-success > .panel-heading {
        color: inherit;
        background: transparent;
        border-bottom: transparent
    }

        .panel-success > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #26a65b
        }

    .panel-success .panel-title {
        color: #fff;
        background: #26a65b;
        border-bottom: 1px solid rgba(255,255,255,0.1)
    }

    .panel-success .panel-footer {
        border-top: 1px solid rgba(255,255,255,0.1);
        background: rgba(255,255,255,0.04)
    }

    .panel-success > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #26a65b
    }

.panel-info {
    border-color: #51b7a3;
    background: #51b7a3;
    color: #fff
}

    .panel-info > .panel-heading {
        color: inherit;
        background: transparent;
        border-bottom: transparent
    }

        .panel-info > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #51b7a3
        }

    .panel-info .panel-title {
        color: #fff;
        background: #51b7a3;
        border-bottom: 1px solid rgba(255,255,255,0.1)
    }

    .panel-info .panel-footer {
        border-top: 1px solid rgba(255,255,255,0.1);
        background: rgba(255,255,255,0.04)
    }

    .panel-info > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #51b7a3
    }

.panel-warning {
    border-color: #f39c12;
    background: #f39c12;
    color: #fff
}

    .panel-warning > .panel-heading {
        color: inherit;
        background: transparent;
        border-bottom: transparent
    }

        .panel-warning > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #f39c12
        }

    .panel-warning .panel-title {
        color: #fff;
        background: #f39c12;
        border-bottom: 1px solid rgba(255,255,255,0.1)
    }

    .panel-warning .panel-footer {
        border-top: 1px solid rgba(255,255,255,0.1);
        background: rgba(255,255,255,0.04)
    }

    .panel-warning > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #f39c12
    }

.panel-danger {
    border-color: #ef4836;
    background: #ef4836;
    color: #fff
}

    .panel-danger > .panel-heading {
        color: inherit;
        background: transparent;
        border-bottom: transparent
    }

        .panel-danger > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #ef4836
        }

    .panel-danger .panel-title {
        color: #fff;
        background: #ef4836;
        border-bottom: 1px solid rgba(255,255,255,0.1)
    }

    .panel-danger .panel-footer {
        border-top: 1px solid rgba(255,255,255,0.1);
        background: rgba(255,255,255,0.04)
    }

    .panel-danger > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #ef4836
    }

.panel-dark {
    border-color: #3d464d;
    background: #3d464d;
    color: #fff
}

    .panel-dark > .panel-heading {
        color: inherit;
        background: transparent;
        border-bottom: transparent
    }

        .panel-dark > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #3d464d
        }

    .panel-dark .panel-title {
        color: #fff;
        background: #3d464d;
        border-bottom: 1px solid rgba(255,255,255,0.1)
    }

    .panel-dark .panel-footer {
        border-top: 1px solid rgba(255,255,255,0.1);
        background: rgba(255,255,255,0.04)
    }

    .panel-dark > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: #3d464d
    }

.panel-tools {
    font-family: 'Roboto',sans-serif;
    position: absolute;
    right: 16px;
    top: 13px;
    text-transform: none;
    font-weight: 600;
    font-size: inherit;
    z-index: 1
}

    .panel-tools li {
        display: inline-block
    }

    .panel-tools a {
        padding: 3px 8px;
        display: block;
        color: inherit;
        border-radius: 3px
    }

        .panel-tools a:hover {
            color: rgba(0,0,0,0.6);
            background: rgba(0,0,0,0.09)
        }

    .panel-tools .icon {
        font-size: 14px;
        color: rgba(0,0,0,0.5);
        border-radius: 3px;
        cursor: pointer
    }

        .panel-tools .icon a {
            color: rgba(0,0,0,0.4)
        }

    .panel-tools .dropdown-menu {
        -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        font-size: 13px
    }

        .panel-tools .dropdown-menu li {
            display: list-item
        }

        .panel-tools .dropdown-menu a {
            display: block
        }

.panel-search {
    margin: -20px;
    padding: 15px 20px;
    position: relative;
    color: #333;
    display: none;
    background: rgba(255,255,255,0.1);
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,0.1)
}

    .panel-search input {
        background: #fff;
        border-radius: 999px;
        padding-left: 35px
    }

        .panel-search input:focus {
            background: #fff
        }

    .panel-search .icon {
        position: absolute;
        left: 35px;
        top: 25px
    }

.panel-fullsize {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 9999
}

.panel-tools-hover {
    display: none
}

.panel:hover .panel-tools-hover {
    display: block
}

.panel-title .titleicon {
    margin-right: 10px
}

.panel-closed .panel-title {
    margin-bottom: -20px
}

.panel-closed .panel-body {
    display: none
}

.widget-tools {
    font-family: 'Roboto',sans-serif;
    position: absolute;
    right: 15px;
    top: 13px;
    text-transform: none;
    font-weight: 600;
    font-size: inherit;
    z-index: 1
}

    .widget-tools li {
        display: inline-block
    }

    .widget-tools a {
        padding: 3px 8px;
        display: block;
        color: inherit;
        border-radius: 3px
    }

        .widget-tools a:hover {
            color: rgba(0,0,0,0.6);
            background: rgba(0,0,0,0.09)
        }

    .widget-tools .icon {
        font-size: 14px;
        color: rgba(0,0,0,0.5);
        border-radius: 3px;
        cursor: pointer
    }

        .widget-tools .icon a {
            color: rgba(0,0,0,0.4)
        }

    .widget-tools .dropdown-menu {
        -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        font-size: 13px
    }

        .widget-tools .dropdown-menu li {
            display: list-item
        }

        .widget-tools .dropdown-menu a {
            display: block
        }

.widget-fullsize {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 9999
}

.widget-tools-hover {
    display: none
}

.widget:hover .widget-tools-hover {
    display: block
}

.modal {
    z-index: 999;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modal-content {
    width: 100%;
    box-shadow: 1px;
}

.modal-dialog {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%) !important;
}

.modal-backdrop {
    z-index: -1;
}

.modal-sm {
    max-width: 400px
}

.modal-lg {
    min-width: 90%
}

.modal-header {
    padding: 0px 25px;
    border-bottom: 1px solid #e5e5e5;
    height: 50px;
}

.modal-body {
    position: relative;
    padding: 10px;
}

.modal .close {
    width: 26px;
    border-radius: 4px;
    font-size: 23px;
    background: #ccc;
    color: #000;
    text-shadow: none;
    opacity: 0.6;
    outline: 0
}

    .modal .close:hover {
        opacity: 0.6
    }

.modal-title {
    font-family: 'Montserrat',sans-serif;
    font-size: 16px
}

.modalicon {
    background: #fff;
    border-radius: 3px;
    text-align: center;
    height: 300px;
    display: table-cell;
    width: 100%;
    position: relative;
    vertical-align: middle;
    border: 2px solid #e2e2e2
}

    .modalicon img {
        border-radius: 3px
    }

    .modalicon:hover {
        border-color: #ccc
    }

.progress {
    height: 18px;
    background: rgba(0,0,0,0.15);
    box-shadow: none
}

.progress-bar {
    background: #399bff;
    font-size: 12px;
    font-weight: 600;
    line-height: normal
}

.progress-bar-success {
    background: #26a65b
}

.progress-bar-info {
    background: #51b7a3
}

.progress-bar-warning {
    background: #f39c12
}

.progress-bar-danger {
    background: #ef4836
}

.progress-bar-transparent {
    background: transparent;
    color: inherit
}

.progress-small {
    height: 9px
}

.progress-large {
    height: 26px
}

.progress-extralarge {
    height: 36px
}

table, td, th {
    border: 1px solid #ddd;
    text-align: center;
}

.table {
    width: 70%;
}

.table-hover > tbody > tr:hover {
    background-color: #EFF7FF
}

thead {
    font-weight: normal;
    font-family: 'Montserrat',sans-serif;
    font-size: 12px;
    color: #37363e
}

    thead .fa {
        font-size: 16px;
        margin: 0
    }

table td {
    display: table-cell;
    vertical-align: middle
}

/*.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 15px;
    line-height: 1.7
}*/

.doc-grid {
    padding: 10px 20px
}

    .doc-grid div {
        border: 1px solid #ccc;
        text-align: center;
        font-size: 16px;
        padding: 20px 0;
        background: #fff
    }

.widget {
    overflow: hidden;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 3px;
    padding: 20px;
    position: relative
}

    .widget .widget-title {
        color: #37363e;
        font-size: 12px;
        font-weight: bold;
        padding: 16px 20px;
        background: #fff;
        margin-bottom: 5px;
        border-bottom: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        margin: -20px;
        margin-bottom: 20px
    }

        .widget .widget-title h5 {
            font-size: 12px;
            z-index: 1;
            margin: 0;
            color: #58666e
        }

        .widget .widget-title h2 {
            font-family: 'Montserrat',sans-serif;
            font-size: 12px;
            margin: 0;
            font-weight: bold;
            text-transform: uppercase
        }

.widget-inline-list {
    display: block;
    padding-left: 0;
    color: #58666e
}

    .widget-inline-list li {
        display: block;
        float: left;
        text-align: center;
        padding: 15px 0
    }

        .widget-inline-list li span {
            font-size: 18px;
            display: block;
            color: inherit;
            font-family: 'Montserrat',sans-serif;
            font-weight: 600
        }

    .widget-inline-list .chart {
        display: block;
        margin-top: 5px
    }

    .widget-inline-list .col-1 {
        width: 8.3333333%
    }

    .widget-inline-list .col-2 {
        width: 16.666666%
    }

    .widget-inline-list .col-3 {
        width: 25%
    }

    .widget-inline-list .col-4 {
        width: 33.333333%
    }

    .widget-inline-list .col-6 {
        width: 50%
    }

    .widget-inline-list .col-12 {
        width: 100%
    }

.chart-with-stats {
    padding: 0
}

    .chart-with-stats .right {
        background: rgba(255,255,255,0.8);
        min-width: 150px;
        position: absolute;
        text-align: center;
        right: 0
    }

        .chart-with-stats .right .title {
            left: 0;
            right: 0;
            text-align: center;
            padding-bottom: 14px
        }

    .chart-with-stats .bigchart {
        height: 300px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: 70px
    }

    .chart-with-stats .top-label {
        position: absolute;
        right: 170px;
        top: 20px;
        background: #f2f2f2;
        border-radius: 3px;
        text-align: right;
        padding: 10px 20px
    }

        .chart-with-stats .top-label h2 {
            font-family: 'Montserrat',sans-serif;
            margin: 0;
            font-weight: bold;
            color: #37363e;
            font-size: 28px
        }

        .chart-with-stats .top-label h4 {
            font-size: 13px;
            margin: 0;
            opacity: 0.7;
            color: #58666e
        }

    .chart-with-stats .title {
        font-family: 'Montserrat',sans-serif;
        color: #58666e;
        font-weight: bold;
        position: absolute;
        left: 20px;
        top: 0px;
        font-size: 13px;
        z-index: 1;
        margin-bottom: 0;
        padding: 0
    }

        .chart-with-stats .title small {
            display: block;
            font-family: 'Roboto',sans-serif;
            font-size: 12px;
            margin-top: 4px
        }

    .chart-with-stats .widget-inline-list {
        position: absolute;
        opacity: 0.9;
        top: 50px;
        bottom: 0
    }

        .chart-with-stats .widget-inline-list span {
            color: #37363e
        }

        .chart-with-stats .widget-inline-list li {
            margin-bottom: 20px
        }

.chart-on-bottom {
    width: 100%;
    position: absolute;
    left: -20px;
    right: -2px;
    bottom: -2px
}

.socialbox .text {
    font-size: 20px;
    color: #fff;
    line-height: 1.6em;
    font-weight: 300
}

.socialbox .text-info {
    color: #fff;
    opacity: 0.7
}

.socialbox .logo {
    color: #fff;
    font-size: 28px;
    left: 20px;
    bottom: 20px;
    position: absolute
}

.socialbox .info {
    color: #fff;
    margin: 0;
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 15px;
    padding: 0
}

    .socialbox .info .fa {
        margin-right: 4px
    }

    .socialbox .info li {
        display: inline;
        padding-left: 10px
    }

.profile-widget {
    padding: 0;
    padding-top: 40px;
    color: #fff;
    text-align: center;
    background: url(../img/socialbg.jpg);
    background-size: cover
}

    .profile-widget .profile-image {
        width: 115px;
        height: 115px;
        border: 4px solid #fff;
        border-radius: 999px;
        margin-bottom: 10px
    }

    .profile-widget .btn {
        background: #fff;
        color: #37363e
    }

        .profile-widget .btn:hover {
            opacity: 0.9
        }

    .profile-widget h1 {
        color: #fff;
        margin: 0;
        font-size: 26px;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
        font-weight: 300
    }

    .profile-widget .stats {
        color: #fff;
        padding: 12px 0;
        position: absolute;
        bottom: -20px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.4)
    }

        .profile-widget .stats span {
            color: #fff
        }

.basic-list {
    margin-bottom: 0;
    padding: 0
}

    .basic-list li {
        display: block;
        position: relative;
        border-bottom: 1px solid rgba(0,0,0,0.06);
        padding: 11px 0
    }

        .basic-list li:last-child {
            border-bottom: none
        }

    .basic-list .right {
        position: absolute;
        right: 0;
        display: inline-block;
        padding: 3px 6px;
        font-weight: 600
    }

.image-list li {
    padding-left: 50px
}

.image-list .img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    border-radius: 999px
}

.image-list .desc {
    font-size: 12px;
    opacity: 0.8;
    display: block
}

.blog-post .image-div {
    border-radius: 3px;
    margin-bottom: 14px;
    position: relative;
    overflow: hidden
}

    .blog-post .image-div a {
        color: #fff
    }

        .blog-post .image-div a:hover {
            opacity: 0.8
        }

.blog-post .image {
    width: 100%;
    min-height: 100px;
    border-radius: 3px;
    top: 0;
    opacity: 0.2
}

.blog-post .title {
    position: absolute;
    top: 0;
    color: #fff;
    padding: 10px;
    margin: 0;
    line-height: normal;
    font-size: 34px;
    font-weight: 300
}

.blog-post .author {
    position: relative;
    padding-left: 50px;
    border-top: 1px solid #ededed;
    padding-top: 20px;
    margin-top: 15px;
    margin-bottom: 0
}

    .blog-post .author img {
        width: 40px;
        height: 40px;
        border-radius: 999px;
        position: absolute;
        left: 0
    }

    .blog-post .author .text {
        margin-bottom: 20px
    }

    .blog-post .author span {
        display: block;
        color: #37363e;
        font-weight: 600
    }

.quick-menu {
    padding: 0;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 3px;
    font-family: 'Montserrat',sans-serif
}

    .quick-menu .label {
        position: absolute;
        padding: 4px 6px;
        top: -10px;
        right: 10px
    }

    .quick-menu li {
        display: block;
        text-align: center;
        padding: 0;
        position: relative;
        font-size: 13px
    }

        .quick-menu li .fa {
            display: block;
            font-size: 28px;
            margin-bottom: 5px;
            opacity: 0.8
        }

    .quick-menu a {
        color: #58666e;
        display: block;
        padding: 18px 0
    }

        .quick-menu a .fa {
            color: #399bff
        }

        .quick-menu a:hover {
            background: rgba(0,0,0,0.03);
            color: #37363e
        }

.gmaps, .gmaps-panaroma {
    height: 300px;
    background: #6699cc;
    border-radius: 3px
}

.gmaps-overlay {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 20px;
    line-height: 40px;
    opacity: 0.8;
    background: #4477aa;
    border: solid 3px #336699;
    border-radius: 4px;
    box-shadow: 2px 2px 10px #333;
    text-shadow: 1px 1px 1px #666;
    padding: 0 4px
}

.gmaps-overlay_arrow {
    left: 50%;
    margin-left: -16px;
    width: 0;
    height: 0;
    position: absolute
}

    .gmaps-overlay_arrow.above {
        bottom: -15px;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 16px solid #336699
    }

    .gmaps-overlay_arrow.below {
        top: -15px;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid #336699
    }

.table-dic .fa {
    text-shadow: none;
    color: #399bff;
    font-size: 20px;
    margin-right: 8px
}

.table-dic .type {
    font-size: 11px;
    opacity: 0.7
}

.card {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-header {
    cursor: pointer;
    border-bottom: 1px solid rgba(0,0,0,.125);
    padding: 10px;
    padding-left: 20px;
}

.card-body {
    padding: 10px;
    padding-left: 30px;
    padding-right: 20px;
}

.w-70 {
    width: 70%;
}

.w-30 {
    width: 30%
}

.display-flex {
    display: flex;
}



/*Added By Bindi*/

.chart-btns {
    width: 30%;
    height: 33px;
    position: relative;
    background-color: #e1000f;
    color: #ffffff;
    border-radius: 4px;
    margin-right: 10px;
    top: 2px;
}

.input-on-error {
    border-width: 2px;
    border-bottom-color: #f00;
}

.home-btn {
    padding-left: 5px !important;
    font-family: sans-serif;
    padding-right: 10px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin-right: -10px;
}

    .home-btn:hover {
        color: white;
        text-decoration: none;
    }

.footer-links {
    color: #76757B;
    padding: 0px 0px 0px 0px
}

.timeline {
    font-size: 16px;
    color: rgb(0, 0, 0);
    font-family: Roboto;
}


.editpanel {
    margin: 0;
    padding: 15px 0px 10px 0px;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    overflow: auto;
    overflow-x: hidden;
}


.color-black {
    color: #000000;
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .login-form {
        width: 100%;
    }
}

.desktop-image {
    position: relative;
    background: url(../img/login-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 700px;
    z-index: -1;
}

.Desktop-form {
    padding: 0px;
}


.btn-upload {
    margin: 0px 10px;
    width: 80px;
    height: 25px;
    padding: 1px;
    float: right;
    position: relative;
    top: 5px;
}


.btn-add {
    margin: 0px 10px;
    width: 80px;
    height: 25px;
    padding: 1px;
    float: right;
    position: relative;
    top: 5px;
}

.btn-download {
    margin: 0px 10px;
    width: 80px;
    height: 25px;
    padding: 1px;
    float: right;
    position: relative;
    top: 5px;
}

.btn-save {
    margin: 0px 10px;
    width: 80px;
    height: 25px;
    padding: 1px;
}

.btn-cancle {
    margin: 0px 10px;
    width: 80px;
    height: 25px;
    padding: 1px;
    color: #50585f;
}

.btn-delete {
    background: transparent;
    border: none;
    width: 40px;
}

.btn-edit {
    background: transparent;
    border: none;
    width: 40px;
}


.show {
    display: block;
}

.hide {
    display: none;
}

.listStyleNone {
    list-style: none;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-5 {
    margin-bottom: 5px;
}


.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.ant-calendar-picker {
    width: 100%;
    padding: 1px 0;
}

.ant-calendar-picker-input.ant-input {
    width: 100%;
    height: 35px;
}


.btn-fileupload {
    width: 80px;
    height: 25px;
    padding: 1px;
    position: relative;
    top: 5px;
    background-color: #98030d;
    color: #fff;
}

.btn-filecancle {
    width: 80px;
    height: 25px;
    padding: 1px;
    position: relative;
    top: 5px;
    color: #50585f;
    margin: 0px 10px;
}

.checkbox {
    height: 15px !important;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #BDC4C9;
    box-shadow: none;
}

.checkbox-rolepage {
    height: 15px !important;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #BDC4C9;
    box-shadow: none;
    margin-top:0px;
}

select {
    width: 100%;
}

input {
    width: 100%;
}

textarea {
    width: 100%;
    height: 80px;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #BDC4C9;
    box-shadow: inset 0px 1px 0px #F1F0F1
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none !important;
}


/*For Dashboard Page*/

body {
    /*background:url(../../img/login-bg.jpg) no-repeat!important;*/
    background: #e3e3e3 !important;
}

/*For login Page
body {
    background: url('/img/login-bg.jpg') no-repeat !important;
    background-size: cover;
}*/

#fm-pivot-view .fm-grid-layout div.fm-header-c, #fm-pivot-view .fm-grid-layout .fm-filters .fm-row .fm-cell {
    background-color: #d9534f !important;
    color: white !important;
}

#fm-pivot-view .fm-grid-layout div.fm-total, #fm-pivot-view .fm-grid-layout div.fm-grand-total {
    background-color: #d9534f !important;
    color: white !important;
}

.fm-charts-color-1 {
    fill: rgb(238, 35, 44) !important
}

.fm-charts-color-2 {
    fill: #828c96 !important
}

.fm-charts-color-3 {
    fill: rgb(238, 35, 44) !important
}

.fm-charts-color-4 {
    fill: #828c96 !important
}

.fm-charts-color-5 {
    fill: rgb(238, 35, 44) !important
}

.fm-charts-color-6 {
    fill: #828c96 !important
}

.fm-charts-color-7 {
    fill: rgb(238, 35, 44) !important
}

.fm-charts-color-8 {
    fill: #828c96 !important
}

.fm-charts-color-9 {
    fill: rgb(238, 35, 44) !important
}

.fm-charts-color-10 {
    fill: none
}


/*For Role Page*/

.role-title {
    width: 24%;
    text-align: center;
}


